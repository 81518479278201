.gam-ad {
  @apply w-full;
}

.gam-ad-wrapper {
  transition: all;
  transition-delay: 300ms;
  transition-duration: 500ms;
}

.gam-ad-section {
  @apply items-center;
}

.w-full > .gam-inreads-pos3-section {
  width: 100%;
}

.gam-oop-pos0 {
  @apply hidden;
  @screen xl {
    @apply block;
    @apply absolute;
    width: 100%;
    height: 100%;
    > div,
    iframe {
      width: 100%;
      /* height 100% on fixed element cause issue in firefox cutting off the iframe
      * switching to 100vh as its supported by all major browser
      */
      height: 100vh;
    }
  }
}

.gam-inreads-pos3 > div {
  display: table;
}

.gam-takeover {
  @media (max-width: 1239px) {
    background: none !important;
  }
}

//caps :after bg with max width avoid overlapping with gam takeover ad
.gam-takeover-body {
  @media (min-width: theme('screens.xl')) {
    @apply relative;
    @apply z-40;
    @apply container;
    @apply mx-auto;
    background-color: white;
    .bg-black-screen,
    .bg-blue-dark-screen,
    .bg-blue-light-screen,
    .bg-gray-100-screen,
    .bg-gray-200-screen {
      &:after {
        max-width: 1280px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .bg-black-scr {
      &:after {
        max-width: 1240px;
        @apply ml-1px;
        left: 50.1%;
        transform: translateX(-50%);
      }
    }
  }
}

.gam-roofrack-pos1 {
  @media (min-width: 0px) and (max-width: 1024px) {
    height: 50px;
  }
  @media (min-width: 1024px) {
    height: 250px;
    max-height: 250px;
  }
}

.gam_annex_posR1 {
  @apply min-h-[250px];
}

.gam-inreads-pos2,
.gam-inreads-pos3 {
  height: auto;
}

.gam-annex-posR1 {
  height: 600px;
}

.gam-annex-posR2 {
  height: 250px;
}

.gam-annex-posR3 {
  @media (min-width: 768px) {
    height: 250px;
  }
}

.gam-annex-posR4 {
  @media (min-width: 768px) {
    height: 250px;
  }
}

.gam-textfixed-pos1,
.gam-textfixed-pos2 {
  @media (min-width: 1024px) {
    height: 56px;
  }

  @media (max-width: 640px) {
    min-height: 96px;
    max-height: 166px;
  }
}

.gam-texttransact-pos1,
.gam-texttransact-pos2,
.gam-texttransact-pos3 {
  width: 100%;

  // set height otherwise empty ads will take up huge empty space
  @media (min-width: 1024px) {
    width: 821px;
    min-height: 43px;
  }

  iframe {
    width: 100%; // this is needed to show the texttransact links on mobile
  }
}

.gam_textstickydesk_pos1 {
  position: fixed;
  left: 0;
  top: 10rem;
  width: 80px;
  z-index: 99;
  background: transparent;

  &.hide {
    visibility: hidden;
  }
}

.gam_textstickymobile_pos1 {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 260px;
  height: 80px;
  z-index: 9999999999;
  background: transparent;

  &.hide {
    visibility: hidden;
  }
}
